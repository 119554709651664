import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginUser } from "../redux/reducers/userReducer";

import { useTheme } from "@emotion/react";
import { useMutation } from "react-query";
import { authenticate } from "../utils/queries";

import { Box, Button, Grid, Stack, TextField, Typography } from "@mui/material";
import { ReactComponent as BuderusStarclubLogo } from "../assets/images/buderus-starclub-logo.svg";
import InfoIcon from "@mui/icons-material/Info";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const loginMutation = useMutation({
    mutationFn: authenticate,
    onSuccess: (user) => {
      dispatch(loginUser({ ...user, isLoggedIn: true }));
      navigate("/");
    },
  });

  const handleLogin = () => {
    const credentials = new FormData();
    credentials.append("username", username);
    credentials.append("password", password);

    loginMutation.mutate(credentials);
  };

  /**
   * Login page
   */
  return (
    <div>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        marginBottom={3}
        sx={{
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.primary.contrastText,
          padding: "10px"
        }}
      >
        <InfoIcon />
        <Typography variant="h6" textAlign="center" marginLeft={1}>
          Login ist offline nicht verfügbar
        </Typography>
      </Box>
      <Grid container justifyContent="center">
        <Grid item="true" xs={12} sm={6} md={4} lg={4} xl={3}>
          <Box m={3} display="flex" flexDirection="column">
            <BuderusStarclubLogo width="100%" />
            <Typography
              variant="h4"
              textAlign="center"
              marginBottom={3}
              marginTop={6}
            >
              Feedback-App
            </Typography>
            <Stack gap={3}>
              <Box>
                <Typography variant="h6" marginBottom={1}>
                  Benutzername:
                </Typography>
                <TextField
                  hiddenLabel
                  fullWidth
                  variant="outlined"
                  id="username-input"
                  type="username"
                  placeholder="Bitte Benutzernamen eingeben"
                  size="medium"
                  sx={{ color: "white" }}
                  onChange={(e) => {
                    setUsername(e.target.value);
                  }}
                />
              </Box>
              <div>
                <Typography variant="h6" marginBottom={1}>
                  Kennwort:
                </Typography>
                <TextField
                  hiddenLabel
                  fullWidth
                  id="password-input"
                  type="password"
                  variant="outlined"
                  color="primary"
                  placeholder="Bitte Kennwort eingeben"
                  size="medium"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
              </div>
              <Stack gap={1} alignItems="center">
                <Button variant="contained" size="large" onClick={handleLogin}>
                  Login
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default Login;
