// PrivateRoute.js
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser, userIsLoggedIn, getLastLoginDate } from "../../redux/reducers/userReducer";
import { checkLoginStatus } from "../../utils/queries";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import LoadingDialog from "../LoadingDialog";

/**
 * Wrapper component for routes to only grant access for logged in users
 */
const PrivateRoute = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => userIsLoggedIn(state));

  const [displayPage, setDisplayPage] = useState(false);   

  const {
    data,
    isLoading
  } = useQuery(["isLoggedIn"], checkLoginStatus, {
    staleTime: 100,
    cacheTime: 100,
  });

  useEffect(() => {
    if(window.navigator.onLine){
      if(!isLoggedIn || data === false){
        dispatch(logoutUser());
        navigate("/login");
        toast.error("Session ist abgelaufen. Bitte loggen Sie sich neu ein.");
      }else if(isLoggedIn && data){
        setDisplayPage(true);
      }
    }else if(isLoggedIn){
      setDisplayPage(true);
    }else{
      setDisplayPage(false);
      dispatch(logoutUser());
      navigate("/login");
      toast.error("Session ist abgelaufen. Bitte loggen Sie sich neu ein.");
    }
  }, [data]);


  if (displayPage) {
    return props.children;
  } else if(isLoading){
    return <LoadingDialog open={isLoading} title={"Session wird geprüft..."} />
  }else{
    return null;
  }
};

export default PrivateRoute;
