import React, { useEffect, useMemo, useState } from "react";
import { useTheme } from "@emotion/react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useModal } from "mui-modal-provider";
import styled from "@emotion/styled";
import { toast } from "react-toastify";

import {
  changeOpenFeedback,
  getActiveFeedback,
} from "../../redux/reducers/feedbackReducer";

import { Offline, Online } from "react-detect-offline";

import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Chip,
  Divider,
  Drawer,
  IconButton,
  Slide,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";
import Close from "@mui/icons-material/Close";
import { ArrowBackIos } from "@mui/icons-material";

import {
  openDatabase,
  addDataToStore,
  deleteFile,
  getFiles,
} from "../../utils/indexedDBService";
import { formatTimestamp } from "../../utils/formatTimestamp";
import CategoryDialog from "../CategoryDialog";
import { downloadFile } from "../../utils/queries";
import { formatFileSize } from "../../utils/formatFileSize";

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

/**
 * Detailview for feedback in a Drawer component
 * Different views for open and sent feedback
 */
export default function DetailView({
  sent,
  open,
  onConfirm,
  onCancel,
  ...props
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { showModal } = useModal();

  const activeFeedback = useSelector((state) => getActiveFeedback(state));

  const [files, setFiles] = useState([]);
  const [mainCategory, setMainCategory] = useState(
    activeFeedback?.mainCategory || { id: null, name: null }
  );
  const [subCategory, setSubCategory] = useState(
    activeFeedback?.subCategory || { id: null, name: null }
  );

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: activeFeedback?.title,
      message: activeFeedback?.message,
      files: activeFeedback?.files,
    },
  });

  const currentValues = watch();

  const usedAttachementSpace = useMemo(() => {
    var size = 0;
    currentValues?.files?.forEach((file) => {
      if (typeof file == "object") {
        size = size + file.size;
      } else if (typeof file == "number") {
        const el = files.filter((e) => e.id == file)[0];
        size = size + el?.data?.size;
      }
    });
    return size;
  }, [currentValues?.files, files]);

  useEffect(() => {
    register("title", { required: true });
    register("message", { required: true });
    register("files");
    fetchFiles();
  }, []);

  const fetchFiles = async () => {
    try {
      const result = await getFiles();
      setFiles(result);
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Triggered on save to change open feedback
   * @param {Object} data -  form data
   * @return {void}
   */
  const onSubmit = async (data) => {
    if (JSON.stringify(activeFeedback?.files) !== JSON.stringify(data.files)) {
      const filesToDelete = activeFeedback?.files?.filter(
        (id) => !data.files?.includes(id)
      );
      const filesToUpload = data.files?.filter(
        (el) => !activeFeedback?.files?.includes(el)
      );

      var updatedFiles = data.files;

      if (filesToDelete) {
        filesToDelete.forEach((id) => {
          deleteFile(id);
        });
      }

      if (filesToUpload) {
        await uploadFiles(filesToUpload).then((ids) => {
          updatedFiles = [
            ...data.files?.filter((el) => typeof el === "number"),
            ...ids,
          ];
        });
      }

      dispatch(
        changeOpenFeedback({
          ...activeFeedback,
          ...data,
          timestamp: new Date().toISOString(),
          mainCategory: mainCategory,
          subCategory: subCategory,
          files: updatedFiles,
        })
      );
    } else {
      dispatch(
        changeOpenFeedback({
          ...activeFeedback,
          ...data,
          timestamp: new Date().toISOString(),
          mainCategory: mainCategory,
          subCategory: subCategory,
        })
      );
    }
    onConfirm();
  };

  /**
   * Adds selected files to the form value for attachements
   * @param {Event} e - File upload event from file input
   * @returns {void}
   */
  const handleUpload = (e) => {
    var fileStore = currentValues.files;
    const files = e.target.files;
    for (var i = 0; i < files.length; i++) {
      //file is smaller than 32MB
      if (files[i].size < 32 * 1024 * 1024) {
        //all files combined are smaller than 100 MB
        if (usedAttachementSpace + files[i].size < 100 * 1024 * 1024) {
          fileStore = [...fileStore, files[i]];
        } else {
          toast.error(
            `Die Anhänge dürfen insgesamt nicht 100 MB überschreiten.`
          );
        }
      } else {
        toast.error(`Datei "${files[i]?.name}" überschreitet 32 MB.`);
      }
    }
    setValue("files", fileStore);
  };

  /**
   * Removes selected file from the form value for files
   * @param {number} index - index of the file in the files array
   * @returns {void}
   */
  const handleFileDelete = (index) => {
    const updatedFiles = currentValues.files;
    if (index !== -1) {
      updatedFiles.splice(index, 1);
      setValue("files", updatedFiles);
    }
  };

  /**
   * Uploads multiple files to indexedDB
   * @param {Array} files - Array of File objects
   * @returns {void}
   */
  const uploadFiles = async (files) => {
    const db = await openDatabase();

    const filePromises = files?.map(async (file) => {
      const key = await addDataToStore(db, { type: "file", data: file });
      return key;
    });
    const fileIds = await Promise.all(filePromises);
    return fileIds;
  };

  /**
   * Opens Dialog to select a category
   * @param {Event} e - The event object representing the click event to select categorys
   * @returns {void}
   */
  const handleSelectCategory = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const categoryDialog = showModal(CategoryDialog, {
      selectedMainCategory: mainCategory,
      selectedSubCategory: subCategory,
      onCancel: () => {
        categoryDialog.destroy();
      },
      onConfirm: (mainCategory, subCategory) => {
        setMainCategory(mainCategory);
        setSubCategory(subCategory);
        categoryDialog.destroy();
      },
    });
  };

  /**
   * Opens Dialog to assign the feedback
   * @param {Event} e - The event object representing the click event to select categorys
   * @param {Event} url - url to navigate to
   * @returns {void}
   */
  const handleSend = (e) => {
    e.preventDefault();
    e.stopPropagation();

    onCancel();

    navigate("/feedback-versenden");
  };

  /**
   * Hidden Input for file upload
   */
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  return (
    <Drawer
      open={open}
      onClose={onCancel}
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          backgroundColor: theme.palette.grayScale.main,
          color: theme.palette.primary.contrastText,
          width: isMobile ? "100%" : "50%",
        },
      }}
    >
      <Stack p={2}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          marginBottom={2}
        >
          <Typography variant="h3" fontWeight="bold">
            {sent ? "Details" : "Feedback bearbeiten"}
          </Typography>
          <IconButton
            size="large"
            onClick={() => {
              onCancel();
            }}
          >
            <Close size="large" />
          </IconButton>
        </Stack>
        {sent && (
          <>
            <Stack direction="column" gap={2} divider={<Divider />}>
              <Stack direction="column" gap={1}>
                <Typography
                  variant="body1"
                  color={theme.palette.grayScale.contrastText}
                >
                  Thema{" "}
                </Typography>

                <Typography variant="body1">
                  {activeFeedback?.title}{" "}
                </Typography>
              </Stack>
              <Stack direction="column" gap={1}>
                <Typography
                  variant="body1"
                  color={theme.palette.grayScale.contrastText}
                >
                  Feedback{" "}
                </Typography>
                <Typography fontWeight="bold" variant="body1">
                  {activeFeedback?.message}{" "}
                </Typography>
              </Stack>
              {activeFeedback?.mainCategory?.name &&
                activeFeedback?.subCategory?.name && (
                  <Stack direction="column" gap={1}>
                    <Typography
                      variant="body1"
                      color={theme.palette.grayScale.contrastText}
                    >
                      Kategorie{" "}
                    </Typography>
                    <Typography fontWeight="bold" variant="body1">
                      {activeFeedback?.mainCategory?.name}
                      {" > "}
                      {activeFeedback?.subCategory?.name}
                    </Typography>
                  </Stack>
                )}
              <Stack direction="column" gap={1}>
                <Typography
                  variant="body1"
                  color={theme.palette.grayScale.contrastText}
                >
                  Empfänger{" "}
                </Typography>
                <Stack direction="row" gap={1} useFlexGap flexWrap="wrap">
                  {activeFeedback?.recipients?.map((contact) => {
                    if (contact?.isMain) {
                      return (
                        <Chip
                          key={`recipient-${contact.id}`}
                          variant="outlined"
                          label={`${contact.firstname} ${contact.lastname} <${contact.email}>`}
                        />
                      );
                    }
                  })}
                </Stack>
              </Stack>
              <Stack direction="column" gap={1}>
                <Typography
                  variant="body1"
                  color={theme.palette.grayScale.contrastText}
                >
                  In Kopie an{" "}
                </Typography>
                <Stack direction="row" gap={1} useFlexGap flexWrap="wrap">
                  {activeFeedback?.recipients?.map((contact) => {
                    if (!contact?.isMain) {
                      return (
                        <Chip
                          key={`copyRecipient-${contact.id}`}
                          variant="outlined"
                          label={`${contact.firstname} ${contact.lastname} <${contact.email}>`}
                        />
                      );
                    }
                  })}
                  {activeFeedback?.ccEmails?.map((email, index) => {
                    return (
                      <Chip
                        key={`ccEmail-${index}`}
                        variant="outlined"
                        label={email}
                      />
                    );
                  })}
                </Stack>
              </Stack>
              <Stack direction="column" gap={1}>
                <Typography
                  variant="body1"
                  color={theme.palette.grayScale.contrastText}
                >
                  Anhänge
                </Typography>
                {!sent && activeFeedback?.files?.length > 0 && (
                  <ul>
                    {activeFeedback?.files?.map((fileId) => {
                      return (
                        <li key={`file-${fileId}`}>
                          <Typography variant="body1">
                            {
                              files?.find((file) => file.id == fileId)?.data
                                ?.name
                            }
                          </Typography>
                        </li>
                      );
                    })}
                  </ul>
                )}
                {sent && activeFeedback?.files?.length > 0 && (
                  <ul>
                    {activeFeedback?.files?.map((file) => {
                      return (
                        <div
                          style={{ cursor: "pointer" }}
                          key={`file-${file?.id}`}
                          onClick={() => {
                            downloadFile(file?.id, file?.srcFileName);
                          }}
                        >
                          <Typography variant="body1">
                            {file?.srcFileName}
                          </Typography>
                        </div>
                      );
                    })}
                  </ul>
                )}
                {activeFeedback?.files?.length == 0 && "-"}
              </Stack>
              <Stack direction="column" gap={1}>
                <Typography
                  variant="body1"
                  color={theme.palette.grayScale.contrastText}
                >
                  Persönliche Nachricht
                </Typography>
                <Typography fontWeight="bold" variant="body1">
                  {activeFeedback?.comment || "-"}
                </Typography>
              </Stack>

              <Typography
                variant="subtitle1"
                align="right"
                marginTop={2}
                color={theme.palette.primary.contrastText}
              >
                Gesendet am {formatTimestamp(activeFeedback?.timestamp)} von{" "}
                {activeFeedback?.creator?.firstname}{" "}
                {activeFeedback?.creator?.lastname}
              </Typography>
            </Stack>
          </>
        )}
        {!sent && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack gap={3}>
              <Alert severity="warning" variant="outlined">
                <strong>
                  Mails an Empfänger außerhalb von MK2 und Q: werden in der
                  Testphase nicht versendet, sondern gehen an TG, CF und MxB. Es
                  geht kein Feedback verloren.
                </strong>
              </Alert>
              <Box>
                <Typography variant="h5" marginBottom={1} display="flex">
                  Thema
                </Typography>
                <TextField
                  hiddenLabel
                  fullWidth
                  placeholder="Worum geht es?"
                  value={currentValues.title}
                  onChange={(e) => {
                    setValue("title", e.target.value);
                  }}
                  error={!!errors?.title}
                />
              </Box>
              <Box>
                <Typography variant="h5" marginBottom={1} display="flex">
                  Feedback{" "}
                </Typography>
                <TextField
                  hiddenLabel
                  fullWidth
                  multiline
                  placeholder="Was möchten Sie mitteilen? Wichtig: Keine Namen/persönliche Daten von Kollegen nennen."
                  variant="outlined"
                  minRows={5}
                  maxRows={5}
                  value={currentValues.message}
                  onChange={(e) => {
                    setValue("message", e.target.value);
                  }}
                  error={!!errors?.message}
                />
              </Box>
              <Online>
                <Box>
                  <Stack
                    onClick={handleSelectCategory}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ cursor: "pointer" }}
                  >
                    <Typography variant="h5">Kategorie</Typography>
                    {mainCategory?.name && subCategory?.name && (
                      <Typography variant="body1">
                        {mainCategory?.name}
                        {" > "}
                        {subCategory?.name}
                      </Typography>
                    )}
                    {!(mainCategory?.name && subCategory?.name) && (
                      <Typography variant="body1">
                        Keine Kategorie ausgewählt
                      </Typography>
                    )}
                    <ArrowBackIos />
                  </Stack>
                </Box>

                <Box display="flex" flexDirection="row" marginTop={3}>
                  <InfoIcon />
                  <Typography variant="h6" textAlign="left" marginLeft={1}>
                    Jedes Feedback ist einer Kategorie zuzuordnen. Bei jeder
                    Kategorie sind die verantwortlichen Ansprechpartner der
                    Fachabteilungen hinterlegt. So sparen Sie sich die Suche
                    nach dem richtigen Ansprechpartner für Ihr Feedback. Diese
                    erhalten Ihren Feedback-Eintrag per E-Mail. Im nächsten
                    Bearbeitungsschritt können Sie darüber hinaus weitere
                    Empfänger hinzufügen. Wenn Sie Ihr Feedback vor dem Versand
                    noch bearbeiten möchten, können Sie es zunächst in Ihren
                    Entwürfen speichern und später versenden.
                  </Typography>
                </Box>
              </Online>
              <Box>
                <Stack gap={1}>
                  {Array.isArray(currentValues.files) &&
                    currentValues.files?.length > 0 &&
                    currentValues.files?.map((el, index) => {
                      return (
                        <Box key={`file-${index}`}>
                          <Stack
                            direction="row"
                            gap={2}
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{ marginBottom: "5px" }}
                          >
                            <Typography>
                              {typeof el == "number"
                                ? `${
                                    files?.find((file) => file.id == el)?.data
                                      ?.name
                                  } (${formatFileSize(
                                    files?.find((file) => file.id == el)?.data
                                      ?.size
                                  )})`
                                : `${el?.name} (${formatFileSize(el?.size)})`}
                            </Typography>
                            <DeleteIcon
                              onClick={() => handleFileDelete(index)}
                            />
                          </Stack>
                        </Box>
                      );
                    })}
                </Stack>
                <Button
                  component="label"
                  variant="contained"
                  onChange={handleUpload}
                  sx={{
                    border: "unset",
                    backgroundColor: "unset",
                    color: theme.palette.secondary.main,
                    fontSize: "1rem",
                    padding: 0,
                    margin: 0,
                    cursor: "pointer",
                    "&:hover": {
                      fontWeight: "900",
                      backgroundColor: "unset",
                      boxShadow: "unset",
                    },
                  }}
                >
                  + Anhang hinzufügen
                  <VisuallyHiddenInput
                    accept=".pdf, .png, .jpeg, .doc, .gif, .csv, .xls, .ppt"
                    type="file"
                    multiple
                  />
                </Button>
                &nbsp; <small>(max. Dateigröße von 32 MB)</small>
              </Box>
              <Box>
                <Typography variant="h5" fontWeight="bold" marginBottom={1}>
                  Details
                </Typography>
                <Stack direction="column" gap={2} divider={<Divider />}>
                  <Stack direction="column" gap={1}>
                    <Typography
                      variant="body1"
                      color={theme.palette.grayScale.contrastText}
                    >
                      In Kopie an{" "}
                    </Typography>
                    <Stack direction="row" gap={1} useFlexGap flexWrap="wrap">
                      {activeFeedback?.copyContacts?.map((contact, index) => {
                        return (
                          <Chip
                            key={`copyContact-${index}`}
                            variant="outlined"
                            label={`${contact.firstname} ${contact.lastname} (${contact.email})`}
                          />
                        );
                      })}
                      {activeFeedback?.ccEmails?.map((email, index) => {
                        return (
                          <Chip
                            key={`ccEmail-${index}`}
                            variant="outlined"
                            label={email}
                          />
                        );
                      })}
                      {activeFeedback?.sendToIPF && (
                        <Chip
                          key={`ipf`}
                          variant="outlined"
                          label={"Ideenplattform"}
                        />
                      )}
                    </Stack>
                  </Stack>
                  <Stack direction="column" gap={1}>
                    <Typography
                      variant="body1"
                      color={theme.palette.grayScale.contrastText}
                    >
                      Persönliche Nachricht
                    </Typography>
                    <Typography fontWeight="bold" variant="body1">
                      {activeFeedback?.comment ?? "-"}
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
              {!sent && (
                <Stack gap={3}>
                  <Button
                    variant="primary"
                    size="large"
                    type="submit"
                    disabled={!currentValues.title || !currentValues.message}
                    sx={{
                      "&:hover": {
                        borderStyle: "solid",
                        borderWidth: "1px",
                        borderColor: theme.palette.primary.contrastText,
                      },
                    }}
                  >
                    Speichern
                  </Button>
                  <Online>
                    <Button
                      variant="secondary"
                      onClick={(e) => {
                        onSubmit(getValues());
                        handleSend(e);
                      }}
                      size="large"
                      disabled={
                        !currentValues.title ||
                        !currentValues.message ||
                        !mainCategory.id ||
                        !subCategory.id
                      }
                      sx={{
                        "&:hover": {
                          borderStyle: "solid",
                          borderWidth: "1px",
                          borderColor: theme.palette.secondary.main,
                        },
                      }}
                    >
                      Feedback versenden
                    </Button>
                  </Online>
                  <Offline>
                    <Stack
                      direction="row"
                      gap={1}
                      justifyContent="center"
                      alignItems="center"
                      marginTop="20px"
                    >
                      <InfoIcon />
                      <Typography variant="body1" display="flex">
                        Das Versenden des Feedbacks ist nur mit einer
                        Internetverbindung möglich.
                      </Typography>
                    </Stack>
                  </Offline>
                </Stack>
              )}
            </Stack>
          </form>
        )}
      </Stack>
    </Drawer>
  );
}
